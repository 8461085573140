import { FC } from "react";
import { Row, Col } from "react-bootstrap";

import { Back } from "src/ui/component/back";
import { Content } from "src/site/section/content";

/**
 * -----------------------------------------------------------------------------
 * Not found page.
 * -----------------------------------------------------------------------------
 */
export const NotFound: FC = () => (
  <Content>
    <Row>
      <Col>
        <Back to="/">Back to Dashboard</Back>
        <h4>Sorry, the page you're looking for could not be found!</h4>
      </Col>
    </Row>
  </Content>
);
