import chroma from "chroma-js";
import { Doughnut } from "react-chartjs-2";
import { FC } from "react";

import { COLOR_PRIMARY } from "src/ui/constant";

import styles from "./quota.module.scss";

/**
 * -----------------------------------------------------------------------------
 * Graph.
 * -----------------------------------------------------------------------------
 */
export const Graph: FC<{ used: number; remaining: number }> = ({
  used,
  remaining,
}) => (
  <div className={styles.Graph}>
    <Doughnut
      data={{
        labels: ["Used", "Remaining"],
        datasets: [
          {
            data: [used, remaining],
            backgroundColor: [
              chroma(COLOR_PRIMARY).alpha(0.75).hex(),
              COLOR_PRIMARY,
            ],
            borderWidth: 0,
          },
        ],
      }}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      }}
    />
  </div>
);
