import { toString } from "lodash";

/**
 * -----------------------------------------------------------------------------
 * Determines the login URL.
 * -----------------------------------------------------------------------------
 */
export const getLoginURL = () => {
  let loginURL = toString(window.env?.LOGIN_URL);

  if (['"', "'"].includes(loginURL.charAt(0))) {
    loginURL = loginURL.slice(1, -1);
  }

  return loginURL;
};
