import { FC } from "react";
import { Card } from "react-bootstrap";

import { Chart } from "./chart.view";

import styles from "./quota.module.scss";

/**
 * -----------------------------------------------------------------------------
 * Quota.
 * -----------------------------------------------------------------------------
 */
export const Quota: FC<{
  used: number;
  remaining: number;
}> = ({ used, remaining }) => (
  <Card className={styles.Quota}>
    <Card.Body className="d-flex flex-column">
      <Card.Title>Quota</Card.Title>
      <Chart used={used} remaining={remaining} />
    </Card.Body>
  </Card>
);
