import { FC } from "react";

import styles from "./features.module.scss";

/**
 * -----------------------------------------------------------------------------
 * Features block.
 * -----------------------------------------------------------------------------
 */
export const Features: FC<{
  rateLimit: number;
  rotatingProxy: boolean;
}> = ({ rateLimit, rotatingProxy }) => (
  <ul className={styles.PlanFeatureList}>
    <li className={[styles.PlanFeatureItem, "bi bi-alarm-fill"].join(" ")}>
      {rateLimit} {rateLimit > 1 ? "requests" : "request"}
      /sec
    </li>
    <li
      className={[
        styles.PlanFeatureItem,
        "bi",
        rotatingProxy
          ? "bi-check-circle-fill"
          : [styles.PlanFeatureItemUnavailable, "bi-x-circle-fill"].join(" "),
      ].join(" ")}
    >
      Rotating proxy
    </li>
  </ul>
);
