import { FC } from "react";
import { Card, Stack } from "react-bootstrap";

import { Email } from "./email.view";
import { APIKey } from "./api-key.view";
import { Usage } from "./usage.view";
import { Plan } from "./plan.view";

import styles from "./account.module.scss";

/**
 * -----------------------------------------------------------------------------
 * Account.
 * -----------------------------------------------------------------------------
 */
export const Account: FC<{
  email: string;
  apiKey: string;
  isLicensed: boolean;
  isFreePlan: boolean;
  license?: { expiresAt: Date };
  payment?: {
    periodEnd: number;
    plan: { name: string; title: string; price: string; cycle: string };
    planChangeType: string;
  };
  plan: { name: string; title: string; price: string; cycle: string };
  usage: {
    limit: number;
    used: number;
  };
}> = ({
  email,
  apiKey,
  isLicensed,
  isFreePlan,
  license,
  payment,
  plan,
  usage: { limit, used },
}) => (
  <Card className={styles.Account}>
    <Card.Body>
      <Card.Title>Account</Card.Title>
      <Stack gap={3}>
        <Email email={email} />
        <APIKey apiKey={apiKey} />
        <Usage limit={limit} used={used} />
        <Plan
          plan={plan}
          license={license}
          payment={payment}
          isLicensed={isLicensed}
          isFreePlan={isFreePlan}
        />
      </Stack>
    </Card.Body>
  </Card>
);
