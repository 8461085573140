import { FC } from "react";
import { Link } from "react-router-dom";

import styles from "./back.module.scss";

/**
 * -----------------------------------------------------------------------------
 * Back component.
 * -----------------------------------------------------------------------------
 */
export const Back: FC<{ to: string }> = ({ to, children }) => (
  <Link to={to} className={[styles.Back, "bi bi-arrow-left"].join(" ")}>
    <span>{children}</span>
  </Link>
);
