import { FC } from "react";
import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";

import styles from "./brand.module.scss";
import logo from "./logo.png";

/**
 * -----------------------------------------------------------------------------
 * Brand.
 * -----------------------------------------------------------------------------
 */
export const Brand: FC = () => (
  <Navbar.Brand>
    <Link to="/" className={styles.Logo}>
      <img src={logo} alt="Siterelic" />
    </Link>
  </Navbar.Brand>
);
