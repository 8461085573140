/**
 * -----------------------------------------------------------------------------
 * Removes URL fragment.
 * -----------------------------------------------------------------------------
 */
export const removeURLFragment = () =>
  window.history.pushState(
    "",
    document.title,
    window.location.pathname + window.location.search,
  );
