import chroma from "chroma-js";
import { Line } from "react-chartjs-2";
import { FC } from "react";

import { COLOR_PRIMARY } from "src/ui/constant";

/**
 * -----------------------------------------------------------------------------
 * Chart.
 * -----------------------------------------------------------------------------
 */
export const Chart: FC<{
  stats: { date: string; used: number }[];
}> = ({ stats }) => (
  <Line
    data={{
      labels: stats.map(({ date }) => date),
      datasets: [
        {
          label: "# of requests",
          data: stats.map(({ used }) => used),
          fill: false,
          borderColor: chroma(COLOR_PRIMARY).alpha(0.5).hex(),
          borderWidth: 2,
          backgroundColor: COLOR_PRIMARY,
        },
      ],
    }}
    options={{
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            precision: 0,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    }}
  />
);
