import type { SpinnerProps } from "react-bootstrap";

import { FC } from "react";
import { Row, Col } from "react-bootstrap";

import { Spinner as SpinnerComponent } from "src/ui/component/spinner";
import { Content } from "src/site/section/content";

import styles from "./spinner.module.scss";

/**
 * -----------------------------------------------------------------------------
 * Spinner section.
 * -----------------------------------------------------------------------------
 */
export const Spinner: FC<{ variant?: SpinnerProps["variant"] }> = ({
  variant,
}) => (
  <Content>
    <Row>
      <Col>
        <div className={styles.Spinner}>
          <SpinnerComponent variant={variant} />
        </div>
      </Col>
    </Row>
  </Content>
);
