/**
 * -----------------------------------------------------------------------------
 * Copies text to clipboard.
 * -----------------------------------------------------------------------------
 */
export const copyToClipboard = ({ source }: { source: string }) => {
  const element = document.getElementById(source) as HTMLTextAreaElement;

  element.focus();
  element.select();

  document.execCommand("copy");
};
