import { removeAccessToken } from "./access-token";

/**
 * -----------------------------------------------------------------------------
 * Performs user logout.
 * -----------------------------------------------------------------------------
 */
export const logout = () => {
  removeAccessToken();
  window.location.replace("/");
};
