import { FC } from "react";
import { Card } from "react-bootstrap";

import styles from "./contact.module.scss";

/**
 * -----------------------------------------------------------------------------
 * Contact.
 * -----------------------------------------------------------------------------
 */
export const Contact: FC = () => (
  <Card className={styles.Contact}>
    <Card.Body>
      <Card.Title>Need Help?</Card.Title>
      <Card.Text>
        <p>
          Explore{" "}
          <a href="https://siterelic.com/docs" target="_blank">
            API Documentation
          </a>{" "}
          to get it started.
        </p>
        <p>
          <a href="https://support.siterelic.com/" target="_blank">
            Contact us
          </a>{" "}
          for any questions you may have.
        </p>
      </Card.Text>
    </Card.Body>
  </Card>
);
