import { isEmpty, isString } from "lodash";

import { getLoginURL, removeURLFragment } from "../helper";

import { getAccessToken, setAccessToken } from "./access-token";

/**
 * -----------------------------------------------------------------------------
 * Redirects to the login URL.
 * -----------------------------------------------------------------------------
 */
export const redirectToLoginURL = () => {
  const loginURL = getLoginURL();

  if (!isEmpty(loginURL)) {
    window.location.replace(loginURL);
  }
};

/**
 * -----------------------------------------------------------------------------
 * Performs user login.
 * -----------------------------------------------------------------------------
 */
export const login = () => {
  const accessToken = getAccessToken();

  if (isString(accessToken)) {
    setAccessToken(accessToken);
  }

  removeURLFragment();
};
