import crypto from "crypto";

/**
 * -----------------------------------------------------------------------------
 * Returns a gravatar URL.
 * -----------------------------------------------------------------------------
 */
export const getAvatar = (email: string, size = 32) =>
  `https://www.gravatar.com/avatar/${crypto
    .createHash("md5")
    .update(email)
    .digest("hex")}?d=mp&s=${size}`;
