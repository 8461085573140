import { FC } from "react";

import styles from "./account.module.scss";

/**
 * -----------------------------------------------------------------------------
 * Email.
 * -----------------------------------------------------------------------------
 */
export const Email: FC<{ email: string }> = ({ email }) => (
  <div className={styles.Item}>
    <div className={styles.ItemTitle}>
      <span className="bi bi-envelope">Email</span>
    </div>
    <div className={styles.ItemValue}>
      <span>{email}</span>
    </div>
  </div>
);
