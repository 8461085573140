import { FC } from "react";
import { Container, Navbar } from "react-bootstrap";

import { Brand } from "../brand";
import { Menu } from "../menu";

import styles from "./header.module.scss";

/**
 * -----------------------------------------------------------------------------
 * Header section.
 * -----------------------------------------------------------------------------
 */
export const Header: FC<{
  email: string;
  isLicensed: boolean;
  isFreePlan: boolean;
  plan: { name: string; title: string; price: string };
  handleLogout: () => any;
}> = ({ email, plan, isLicensed, isFreePlan, handleLogout }) => (
  <Navbar as="header" className={styles.Header}>
    <Container>
      <Brand />
      <Menu
        email={email}
        plan={plan}
        isLicensed={isLicensed}
        isFreePlan={isFreePlan}
        handleLogout={handleLogout}
      />
    </Container>
  </Navbar>
);
