import { FC } from "react";
import { Card } from "react-bootstrap";

import { Chart } from "./chart.view";

/**
 * -----------------------------------------------------------------------------
 * Usage.
 * -----------------------------------------------------------------------------
 */
export const Usage: FC<{
  stats: { date: string; used: number }[];
}> = ({ stats }) => (
  <Card>
    <Card.Body>
      <Card.Title>Usage history</Card.Title>
      <Chart stats={stats} />
    </Card.Body>
  </Card>
);
