import { FC, ChangeEvent, useState } from "react";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import useAxios from "axios-hooks";

import { getAPIEndpoint } from "src/core/app";
import { Heading } from "src/ui/component/heading";
import { Spinner } from "src/ui/component/spinner";
import { Back } from "src/ui/component/back";
import { Content } from "src/site/section/content";

import styles from "./license-activation.module.scss";

/**
 * -----------------------------------------------------------------------------
 * License activation page.
 * -----------------------------------------------------------------------------
 */
export const LicenseActivation: FC = () => {
  const [licenseCode, setLicenseCode] = useState("");

  const [{ loading, data, error }, activate] = useAxios(
    {
      method: "PUT",
      url: getAPIEndpoint({ path: `/licenses/${licenseCode}/activate` }),
    },
    {
      manual: true,
    },
  );

  if (data) {
    const {
      data: { isActive },
    } = data;

    if (isActive) {
      window.setTimeout(() => window.location.replace("/"), 1000);
    }
  }

  return (
    <Content>
      <Row>
        <Col>
          <div className={styles.LicenseActivation}>
            <Back to="/">Back to Dashboard</Back>
            <Heading>Redeem Code</Heading>

            {data && data.data.isActive && (
              <Alert
                variant="success"
                className={styles.LicenseActivationAlert}
              >
                Successfully redeemed.
                <br />
                <br />
                <small>Redirecting to the dashboard...</small>
              </Alert>
            )}

            {error &&
              error.response?.data?.statusCode >= 400 &&
              error.response?.data?.statusCode < 500 && (
                <Alert
                  variant="danger"
                  className={styles.LicenseActivationAlert}
                >
                  Invalid redeem code!
                  <br />
                  <br />
                  <small>Please enter a valid code.</small>
                </Alert>
              )}

            {error && error.response?.data?.statusCode >= 500 && (
              <Alert variant="danger" className={styles.LicenseActivationAlert}>
                Something went wrong!
                <br />
                <br />
                <small>Please try again.</small>
              </Alert>
            )}

            <Form>
              <Form.Group>
                <Form.Label>Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter code to redeem"
                  value={licenseCode}
                  className={styles.LicenseActivationInput}
                  onChange={({ target: { value } }) =>
                    setLicenseCode(value.trim())
                  }
                />
              </Form.Group>
              <Button
                variant="primary"
                disabled={loading}
                className={styles.LicenseActivationButton}
                onClick={() => activate()}
              >
                {loading ? (
                  <>
                    <Spinner /> Redeeming
                  </>
                ) : (
                  <>Redeem</>
                )}
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Content>
  );
};
