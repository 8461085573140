import { FC } from "react";
import { Link } from "react-router-dom";

import { format } from "date-fns";

import styles from "./account.module.scss";

/**
 * -----------------------------------------------------------------------------
 * Plan.
 * -----------------------------------------------------------------------------
 */
export const Plan: FC<{
  isLicensed: boolean;
  isFreePlan: boolean;
  license?: { expiresAt: Date };
  payment?: {
    periodEnd: number;
    plan: { name: string; title: string; price: string; cycle: string };
    planChangeType: string;
  };
  plan: { name: string; title: string; price: string; cycle: string };
}> = ({
  isLicensed,
  isFreePlan,
  license: { expiresAt } = {},
  payment: { periodEnd = 0, plan: paymentPlan, planChangeType } = {},
  plan: { title, cycle },
}) => (
  <div className={[styles.Subscription, styles.Item].join(" ")}>
    <div className={styles.ItemTitle}>
      <span className="bi bi-credit-card">Subscription</span>
    </div>
    <div className={styles.ItemValue}>
      <div className={styles.Plan}>
        <strong className={styles.PlanTitle}>{title}</strong>
        <span className={styles.PlanCycle}>{cycle}</span>
      </div>
      {!isLicensed || !expiresAt ? null : (
        <div className={styles.Schedule}>
          Will expire on {format(new Date(expiresAt), "MMMM d, yyyy")}
        </div>
      )}
      {isLicensed || !planChangeType ? null : (
        <div className={styles.Schedule}>
          Will {planChangeType} to{" "}
          <strong>
            {paymentPlan?.title} ({paymentPlan?.cycle})
          </strong>{" "}
          on {format(periodEnd * 1000, "MMMM d, yyyy")}
        </div>
      )}
      {isLicensed || isFreePlan || paymentPlan?.name === "free" ? null : (
        <span className={styles.Schedule}>
          Will bill <strong>{paymentPlan?.price}</strong> on{" "}
          {format(periodEnd * 1000, "MMMM d, yyyy")}
        </span>
      )}{" "}
      {isLicensed || isFreePlan ? null : (
        <Link className={styles.ManageBilling} to="/app/billing">
          Manage Billing
        </Link>
      )}
      {isLicensed || isFreePlan ? null : (
        <div className={styles.Divider}>|</div>
      )}
      {isLicensed ? null : (
        <Link className={styles.ChangePlan} to="/app/subscribe">
          Change Plan
        </Link>
      )}
    </div>
  </div>
);
