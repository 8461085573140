import { FC, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import { getAvatar } from "src/core/app";

import styles from "./menu.module.scss";

/**
 * -----------------------------------------------------------------------------
 * Menu.
 * -----------------------------------------------------------------------------
 */
export const Menu: FC<{
  email: string;
  isLicensed: boolean;
  isFreePlan: boolean;
  plan: { name: string; title: string; price: string };
  handleLogout: () => any;
}> = ({ email, plan, isLicensed, isFreePlan, handleLogout }) => {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const menuDropdownRef = useRef<HTMLDivElement>(null);
  const collapseMenu = () => setIsMenuExpanded(false);
  const toggleMenu = () => setIsMenuExpanded(!isMenuExpanded);

  useEffect(() => {
    const handleClickOutsideMenuDropdown = (event: MouseEvent) =>
      menuDropdownRef.current &&
      event.target instanceof HTMLElement &&
      !menuDropdownRef.current.contains(event.target) &&
      collapseMenu();
    document.addEventListener("mousedown", handleClickOutsideMenuDropdown);
    return () =>
      document.removeEventListener("mousedown", handleClickOutsideMenuDropdown);
  }, [menuDropdownRef]);

  return (
    <div className={[styles.Menu, isMenuExpanded ? "Expanded" : ""].join(" ")}>
      <div className={styles.MenuAvatar} onClick={toggleMenu}>
        <img src={getAvatar(email)} />
      </div>
      <div ref={menuDropdownRef} className={styles.MenuDropdown}>
        <div className={styles.MenuUser}>
          <div className={styles.MenuUserHeading}>Logged in as</div>
          <div className={styles.MenuUserEmail}>{email}</div>
        </div>
        <ul className={styles.MenuList} onClick={collapseMenu}>
          <li className={`${styles.MenuItem} bi bi-journal-code`}>
            <a href="https://siterelic.com/docs" target="_blank">
              Documentation
            </a>
          </li>
          {isLicensed || isFreePlan ? null : (
            <li className={`${styles.MenuItem} bi bi-receipt`}>
              <Link to="/app/billing">Manage Billing</Link>
            </li>
          )}
          {isLicensed ? null : (
            <li className={`${styles.MenuItem} bi bi-key`}>
              <Link to="/app/redeem">Redeem Code</Link>
            </li>
          )}
          {isLicensed ? null : (
            <li className={`${styles.MenuItem} bi bi-tags`}>
              <Link to="/app/subscribe">Change Plan</Link>
            </li>
          )}
          <li className={`${styles.MenuItem} bi bi-currency-dollar`}>
            <a href="https://siterelic.getrewardful.com/" target="_blank">
              Affiliates
            </a>
          </li>
          <li className={`${styles.MenuItem} bi bi-question-circle`}>
            <a href="https://support.siterelic.com/" target="_blank">
              Support
            </a>
          </li>
          <li className={`${styles.MenuItem} bi bi-lock`}>
            <Link to="/" onClick={handleLogout}>
              Logout
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
