import { FC } from "react";
import { Row, Col } from "react-bootstrap";

import { Content } from "src/site/section/content";

import { Account } from "../account/account.view";
import { Quota } from "../quota/quota.view";
import { Contact } from "../contact/contact.view";
import { Usage } from "../usage/usage.view";

import styles from "./dashboard.module.scss";

/**
 * -----------------------------------------------------------------------------
 * Dashboard page.
 * -----------------------------------------------------------------------------
 */
export const Dashboard: FC<{
  email: string;
  apiKey: string;
  isLicensed: boolean;
  isFreePlan: boolean;
  license?: { expiresAt: Date };
  payment?: {
    periodEnd: number;
    plan: { name: string; title: string; price: string; cycle: string };
    planChangeType: string;
  };
  plan: { name: string; title: string; price: string; cycle: string };
  usage: {
    limit: number;
    used: number;
    remaining: number;
    stats: {
      date: string;
      used: number;
      remaining: number;
    }[];
  };
}> = ({
  email,
  apiKey,
  isLicensed,
  isFreePlan,
  license,
  payment,
  plan,
  usage,
}) => (
  <Content>
    <Row>
      <Col sm={12} md={{ span: 7 }} className={styles.Section}>
        <Account
          email={email}
          apiKey={apiKey}
          isLicensed={isLicensed}
          isFreePlan={isFreePlan}
          license={license}
          payment={payment}
          plan={plan}
          usage={usage}
        />
      </Col>
      <Col sm={12} md={{ span: 5 }} className={styles.Section}>
        <Quota used={usage.used} remaining={usage.remaining} />
      </Col>
    </Row>
    <Row>
      <Col>
        <Contact />
      </Col>
    </Row>
    <Row>
      <Col>
        <Usage stats={usage.stats} />
      </Col>
    </Row>
  </Content>
);
