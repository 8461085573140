import { FC, useState } from "react";
import { Row, Col } from "react-bootstrap";

import { Heading } from "src/ui/component/heading";
import { Back } from "src/ui/component/back";
import { Plan } from "src/site/block/plan";
import { Content } from "src/site/section/content";

import styles from "./subscribe.module.scss";

/**
 * -----------------------------------------------------------------------------
 * Subscription plan page.
 * -----------------------------------------------------------------------------
 */
export const Subscribe: FC<{
  plans: {
    name: string;
    title: string;
    price: string;
    cycle: string;
    quotaLimit: number;
    features: {
      rateLimit: number;
      rotatingProxy: boolean;
    };
  }[];
  plan: { name: string; cycle: string; quotaLimit: number };
}> = ({ plans, plan }) => {
  const [planGroup, setPlanGroup] = useState("monthly");

  return (
    <Content>
      <Row>
        <Col>
          <div className={styles.Subscribe}>
            <Back to="/">Back to Dashboard</Back>
            <Heading>Change subscription plan</Heading>
            <ul className={styles.PlanGroup}>
              {[
                ["monthly", "Monthly"],
                ["yearly", "Yearly"],
              ].map(([planGroupName, planGroupTitle]) => (
                <li
                  className={[
                    styles.PlanGroupTab,
                    planGroupName === planGroup
                      ? styles.PlanGroupTabActive
                      : "",
                  ].join(" ")}
                  onClick={() => setPlanGroup(planGroupName)}
                >
                  {planGroupTitle}
                </li>
              ))}
            </ul>
            <ul className={styles.PlanList}>
              {plans
                .filter(({ cycle }) => cycle === planGroup)
                .map((p) => (
                  <li key={p.name} className={styles.PlanItem}>
                    <Plan
                      {...{
                        ...p,
                        currentPlan: plan,
                        className: styles.PlanItemWrapper,
                      }}
                    />
                  </li>
                ))}
            </ul>
          </div>
        </Col>
      </Row>
    </Content>
  );
};
