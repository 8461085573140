import type { SpinnerProps } from "react-bootstrap";

import { FC } from "react";
import { Spinner as BootstrapSpinner } from "react-bootstrap";

/**
 * -----------------------------------------------------------------------------
 * Spinner component.
 * -----------------------------------------------------------------------------
 */
export const Spinner: FC<{ variant?: SpinnerProps["variant"] }> = ({
  variant,
}) => <BootstrapSpinner animation="border" variant={variant} />;
