import { FC } from "react";

import { Graph } from "./graph.view";
import { Legend } from "./legend.view";

import styles from "./quota.module.scss";

/**
 * -----------------------------------------------------------------------------
 * Chart.
 * -----------------------------------------------------------------------------
 */
export const Chart: FC<{ used: number; remaining: number }> = ({
  used,
  remaining,
}) => (
  <div className={styles.Chart}>
    <Graph used={used} remaining={remaining} />
    <Legend used={used} remaining={remaining} />
  </div>
);
