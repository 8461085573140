import { FC } from "react";
import { Link } from "react-router-dom";

import { Features } from "../features";

import styles from "./plan.module.scss";

/**
 * -----------------------------------------------------------------------------
 * Plan block.
 * -----------------------------------------------------------------------------
 */
export const Plan: FC<{
  name: string;
  title: string;
  price: string;
  cycle: string;
  quotaLimit: number;
  features: {
    rateLimit: number;
    rotatingProxy: boolean;
  };
  currentPlan: {
    name: string;
    cycle: string;
    quotaLimit: number;
  };
  className?: string;
}> = ({
  name,
  title,
  price,
  cycle,
  quotaLimit,
  features,
  currentPlan: { name: currentName, quotaLimit: currentQuotaLimit },
  className = "",
}) => (
  <div className={[styles.Plan, className].join(" ")}>
    <h3 className={styles.PlanHeading}>{title}</h3>
    <div className={styles.PlanRequestCount}>
      {quotaLimit.toLocaleString()} requests
    </div>

    <div className={styles.PlanPrice}>
      <span className={styles.PlanPriceAmount}>{price}</span>
      <span className={styles.PlanPriceCycle}>
        /{cycle === "yearly" ? "year" : "mo"}
      </span>
    </div>

    {name === currentName ? (
      <div
        className={[styles.PlanButton, "btn btn-primary disabled"].join(" ")}
      >
        Current Plan
      </div>
    ) : (
      <Link
        to={`/app/subscribe/${name}`}
        className={[styles.PlanButton, "btn btn-primary"].join(" ")}
      >
        {quotaLimit === currentQuotaLimit
          ? "Switch"
          : quotaLimit > currentQuotaLimit
          ? "Upgrade"
          : "Downgrade"}
      </Link>
    )}

    <Features {...features} />
  </div>
);
