import { FC } from "react";
import { Row, Col, Alert } from "react-bootstrap";
import useAxios from "axios-hooks";

import { getAPIEndpoint } from "src/core/app";
import { Heading } from "src/ui/component/heading";
import { Back } from "src/ui/component/back";
import { Content } from "src/site/section/content";

/**
 * -----------------------------------------------------------------------------
 * Billing portal page.
 * -----------------------------------------------------------------------------
 */
export const BillingPortal: FC<{ userID: string }> = ({ userID }) => {
  const apiEndpoint = getAPIEndpoint({ path: "/sessions/billing-portal" });
  const [{ loading, data, error }] = useAxios({
    method: "POST",
    url: apiEndpoint,
  });

  if (data) {
    const {
      data: { portalURL },
    } = data;

    if (portalURL) {
      window.location.replace(portalURL);
    } else {
      window.location.replace("/");
    }
  }

  return (
    <Content>
      <Row>
        <Col>
          <div>
            <Back to="/">Back to Dashboard</Back>
            <Heading>Billing Portal</Heading>
            {loading && (
              <Alert variant="secondary">Getting portal ready...</Alert>
            )}

            {data && data.data.portalURL && (
              <Alert variant="secondary">
                Redirecting to the portal...
                <br />
                <br />
                <small>
                  <a href={data.data.portalURL}>Click here</a> to continue.
                </small>
              </Alert>
            )}

            {error && (
              <Alert variant="danger">
                Something went wrong!
                <br />
                <br />
                <small>Please try again.</small>
              </Alert>
            )}
          </div>
        </Col>
      </Row>
    </Content>
  );
};
