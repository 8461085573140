import { FC } from "react";
import { Container } from "react-bootstrap";

import styles from "./content.module.scss";

/**
 * -----------------------------------------------------------------------------
 * Content section.
 * -----------------------------------------------------------------------------
 */
export const Content: FC = ({ children }) => (
  <Container as="main" className={styles.Content}>
    {children}
  </Container>
);
