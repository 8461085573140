/**
 * -----------------------------------------------------------------------------
 * Retrieves the access token from URL.
 * -----------------------------------------------------------------------------
 */
export const getAccessTokenFromURL = () => {
  const urlHash = window.location.hash.substring(1);
  const urlParams = new URLSearchParams(urlHash);
  const accessToken = urlParams.get("access_token");

  return accessToken;
};

/**
 * -----------------------------------------------------------------------------
 * Retrieves the access token from local storage.
 * -----------------------------------------------------------------------------
 */
export const getAccessTokenFromLocalStorage = () =>
  localStorage.getItem("accessToken");
