import {
  getAccessTokenFromURL,
  getAccessTokenFromLocalStorage,
} from "../helper";

/**
 * -----------------------------------------------------------------------------
 * Retrieves the access token.
 * -----------------------------------------------------------------------------
 */
export const getAccessToken = () => {
  let accessToken = getAccessTokenFromURL();

  if (!accessToken) {
    accessToken = getAccessTokenFromLocalStorage();
  }

  return accessToken;
};

/**
 * -----------------------------------------------------------------------------
 * Stores an access token.
 * -----------------------------------------------------------------------------
 */
export const setAccessToken = (accessToken: string) =>
  localStorage.setItem("accessToken", accessToken);

/**
 * -----------------------------------------------------------------------------
 * Deletes the access token.
 * -----------------------------------------------------------------------------
 */
export const removeAccessToken = () => localStorage.removeItem("accessToken");
