import { FC } from "react";

import styles from "./heading.module.scss";

/**
 * -----------------------------------------------------------------------------
 * Heading component.
 * -----------------------------------------------------------------------------
 */
export const Heading: FC = ({ children }) => (
  <h1 className={styles.Heading}>{children}</h1>
);
