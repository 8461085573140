import { getAccessToken } from "./access-token";

/**
 * -----------------------------------------------------------------------------
 * Determines an API endpoint.
 * -----------------------------------------------------------------------------
 */
export const getAPIEndpoint = ({ path }: { path: string }) => {
  const accessToken = getAccessToken();
  const apiEndpoint = `/api${path}?accessToken=${accessToken}`;

  return apiEndpoint;
};
