import { FC } from "react";

import styles from "./account.module.scss";

/**
 * -----------------------------------------------------------------------------
 * Usage.
 * -----------------------------------------------------------------------------
 */
export const Usage: FC<{ limit: number; used: number }> = ({ limit, used }) => (
  <div className={styles.Item}>
    <div className={styles.ItemTitle}>
      <span className="bi bi-bar-chart">API usage</span>
    </div>
    <div className={styles.ItemValue}>
      <span>
        {used.toLocaleString()}/{limit.toLocaleString()}
      </span>
    </div>
  </div>
);
