import { FC, StrictMode } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useAxios from "axios-hooks";

import { Header } from "src/site/section/header";
import { Spinner } from "src/site/section/spinner";
import { Alert } from "src/site/section/alert";

import { Dashboard } from "src/site/page/dashboard";
import { Subscribe } from "src/site/page/subscribe";
import { BillingPortal } from "src/site/page/billing-portal";
import { SubscribePlan } from "src/site/page/subscribe-plan";
import { LicenseActivation } from "src/site/page/license-activation";
import { NotFound } from "src/site/page/not-found";

import {
  login,
  logout,
  isAuthError,
  getAPIEndpoint,
  redirectToLoginURL,
} from "../app";

import "src/ui/framework.scss";

/**
 * -----------------------------------------------------------------------------
 * Application.
 * -----------------------------------------------------------------------------
 */
export const App: FC = () => {
  const apiEndpoint = getAPIEndpoint({ path: "/dashboard" });

  const [{ loading, data, error }] = useAxios(apiEndpoint);

  if (loading) {
    return <Spinner variant="primary" />;
  }

  if (error) {
    if (isAuthError(error)) {
      redirectToLoginURL();
      return <Alert>Redirecting to the login page...</Alert>;
    }

    return <Alert>Something went wrong!</Alert>;
  }

  login();

  const {
    data: {
      userID,
      email,
      apiKey,
      isLicensed,
      isFreePlan,
      license,
      payment,
      plan,
      plans,
      usage,
    },
  } = data;

  return (
    <StrictMode>
      <BrowserRouter>
        <Header
          {...{
            email,
            plan,
            isLicensed,
            isFreePlan,
            handleLogout: logout,
          }}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Dashboard
                {...{
                  email,
                  apiKey,
                  isLicensed,
                  isFreePlan,
                  license,
                  payment,
                  plan,
                  usage,
                }}
              />
            }
          />
          {isLicensed || isFreePlan ? null : (
            <Route
              path="/app/billing"
              element={<BillingPortal {...{ userID }} />}
            />
          )}
          {isLicensed ? null : (
            <Route
              path="/app/subscribe"
              element={<Subscribe {...{ plan, plans }} />}
            />
          )}
          {isLicensed ? null : (
            <Route
              path="/app/subscribe/:plan"
              element={<SubscribePlan {...{ plan, plans }} />}
            />
          )}
          {isLicensed ? null : (
            <Route path="/app/redeem" element={<LicenseActivation />} />
          )}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </StrictMode>
  );
};
