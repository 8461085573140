import { FC } from "react";

import { copyToClipboard } from "src/core/app";

import styles from "./account.module.scss";

/**
 * -----------------------------------------------------------------------------
 * API key.
 * -----------------------------------------------------------------------------
 */
export const APIKey: FC<{ apiKey: string }> = ({ apiKey }) => (
  <div className={`${styles.Item} ${styles.APIKey}`}>
    <div className={styles.ItemTitle}>
      <span className="bi bi-key">API key</span>
    </div>
    <div className={styles.ItemValue}>
      <span>
        <code>{apiKey}</code>
        <textarea id="api-key" value={apiKey} readOnly />
        <i
          className="bi bi-clipboard"
          onClick={() => copyToClipboard({ source: "api-key" })}
        ></i>
      </span>
    </div>
  </div>
);
