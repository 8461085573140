import { FC } from "react";
import { Row, Col, Alert as BootstrapAlert } from "react-bootstrap";

import { Content } from "src/site/section/content";

/**
 * -----------------------------------------------------------------------------
 * Alert section.
 * -----------------------------------------------------------------------------
 */
export const Alert: FC<{ variant?: string }> = ({
  children,
  variant = "secondary",
}) => (
  <Content>
    <Row>
      <Col>
        <BootstrapAlert variant={variant}>{children}</BootstrapAlert>
      </Col>
    </Row>
  </Content>
);
