import { FC } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Button, Alert } from "react-bootstrap";
import useAxios from "axios-hooks";

import { getAPIEndpoint } from "src/core/app";
import { Heading } from "src/ui/component/heading";
import { Spinner } from "src/ui/component/spinner";
import { Back } from "src/ui/component/back";
import { Features } from "src/site/block/plan/view/features";
import { Content } from "src/site/section/content";
import { NotFound } from "src/site/page/not-found";

import planStyles from "src/site/block/plan/view/plan/plan.module.scss";
import styles from "./subscribe-plan.module.scss";

declare function rewardful(...args: any[]): any;

/**
 * -----------------------------------------------------------------------------
 * Subscription confirmation page.
 * -----------------------------------------------------------------------------
 */
export const SubscribePlan: FC<{
  plans: {
    name: string;
    title: string;
    price: string;
    cycle: string;
    quotaLimit: number;
    features: {
      rateLimit: number;
      rotatingProxy: boolean;
    };
  }[];
  plan: {
    name: string;
    title: string;
    price: string;
    cycle: string;
    quotaLimit: number;
  };
}> = ({
  plans,
  plan: {
    name: currentName,
    title: currentTitle,
    price: currentPrice,
    cycle: currentCycle,
    quotaLimit: currentQuotaLimit,
  },
}) => {
  const { plan: planName } = useParams();

  const plan = plans.find(({ name }) => name === planName);

  if (!plan || planName === currentName) {
    return <NotFound />;
  }

  const apiEndpoint = getAPIEndpoint({ path: "/subscriptions" });
  const [{ loading, data, error }, subscribe] = useAxios(
    {
      method: "POST",
      url: apiEndpoint,
      data: { planName },
    },
    {
      manual: true,
    },
  );

  if (data) {
    const {
      data: { email, checkoutURL },
    } = data;

    if (checkoutURL) {
      window.location.replace(checkoutURL);
    } else {
      rewardful("convert", { email });
      window.setTimeout(() => window.location.replace("/"), 5000);
    }
  }

  const { title, price, cycle, quotaLimit, features } = plan;

  return (
    <Content>
      <Row>
        <Col>
          <div className={styles.SubscribePlan}>
            <Back to="/app/subscribe">Back to Plans</Back>
            <Heading>Confirm subscription plan</Heading>
            <div className={[planStyles.Plan, styles.Plan].join(" ")}>
              <h3 className={planStyles.PlanHeading}>{title}</h3>
              <div className={planStyles.PlanRequestCount}>
                {quotaLimit.toLocaleString()} requests
              </div>

              <div className={planStyles.PlanPrice}>
                <span className={planStyles.PlanPriceAmount}>{price}</span>
                <span className={planStyles.PlanPriceCycle}>
                  /{cycle === "yearly" ? "year" : "mo"}
                </span>
              </div>

              <div className={planStyles.PlanDescription}>
                Your current plan is <span>{currentTitle}</span>, which includes{" "}
                <span>{currentQuotaLimit.toLocaleString()}</span> requests for{" "}
                <span>
                  {currentPrice}/{currentCycle === "yearly" ? "year" : "mo"}
                </span>
                .
              </div>

              {data && !data.data.checkoutURL && (
                <Alert variant="success" className={planStyles.PlanAlert}>
                  Successfully{" "}
                  {data.data.changeType === "switch"
                    ? "switched"
                    : data.data.changeType === "upgrade"
                    ? "upgraded"
                    : "downgraded"}{" "}
                  to the <strong>{data.data.plan.title}</strong> ({cycle}) plan.
                  <br />
                  <br />
                  <small>Redirecting to the dashboard...</small>
                </Alert>
              )}

              {error && (
                <Alert variant="danger" className={planStyles.PlanAlert}>
                  Something went wrong!
                  <br />
                  <br />
                  <small>Please try again.</small>
                </Alert>
              )}

              <Button
                variant="primary"
                disabled={loading}
                className={planStyles.PlanButton}
                onClick={() => subscribe()}
              >
                {loading ? (
                  <>
                    <Spinner />{" "}
                    {quotaLimit === currentQuotaLimit
                      ? "Switching"
                      : quotaLimit > currentQuotaLimit
                      ? "Upgrading"
                      : "Downgrading"}
                  </>
                ) : (
                  <>
                    Confirm{" "}
                    {quotaLimit === currentQuotaLimit
                      ? "Switch"
                      : quotaLimit > currentQuotaLimit
                      ? "Upgrade"
                      : "Downgrade"}
                  </>
                )}
              </Button>

              <Features {...features} />
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
};
