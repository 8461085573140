import { FC } from "react";

import styles from "./quota.module.scss";

/**
 * -----------------------------------------------------------------------------
 * Legend.
 * -----------------------------------------------------------------------------
 */
export const Legend: FC<{ used: number; remaining: number }> = ({
  used,
  remaining,
}) => (
  <div className={styles.LegendList}>
    <div className={`bi bi-circle-fill ${styles.LegendItem} ${styles.Used}`}>
      Used ({used.toLocaleString()})
    </div>
    <div
      className={`bi bi-circle-fill ${styles.LegendItem} ${styles.Remaining}`}
    >
      Remaining ({remaining.toLocaleString()})
    </div>
  </div>
);
